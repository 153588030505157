



























































































import {ISource} from "@/services/addOrUpdateOrDeleteService";
import _ from "lodash";
import {PropType} from "vue";
import {
  Component,
  Emit,
  ModelSync,
  Prop,
  PropSync,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import {
  BCard,
  BCol,
  BRow,
  BForm,
  BFormCheckbox,
  BButton,
  BCardFooter,
  BSpinner,
  BSkeletonWrapper,
  BSkeleton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {mapActions, mapState} from 'vuex';
import {State, Mutation} from 'vuex-class';
import {Can} from "@casl/vue";

@Component({
  components: {
    BCard,
    BCol,
    BRow,
    BForm,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BCardFooter,
    BSpinner,
    Can,
    BSkeletonWrapper,
    BSkeleton
  },
  directives: {
    Ripple,

  },
})
export default class FormComponent extends Vue {
  @ModelSync("sourceData", "input", {
    type: Object as PropType<ISource>,
  })
  formData!: ISource;

  @PropSync("copyData", {
    type: Object as PropType<ISource>,
  })
  formDataCopy!: ISource;

  @PropSync("copyAddress", {
    type: Object as PropType<ISource>,
    default: () => ({}),
  })
  addressCopy!: ISource;

  @Prop({default: "any"})
  addressField!: String;

  @Prop()
  showStatus!: boolean;

  @Prop()
  validate!: boolean;

  @Prop()
  showHeader!: boolean;

  @Prop({default: false})
  showDelete!: Boolean;

  @Emit()
  save() {
    console.log("form save triggerd");
    console.log(this.formDataCopy);
    if (_.isEmpty(this.addressCopy) == false) {
      console.log(this.addressCopy, "not empty");
      this.formDataCopy[this.addressField.toString()] = this.addressCopy;
    }
    if (_.isEmpty(this.formDataCopy) == false) {
      console.log("formDataCopyNotEmpty", this.formDataCopy, this.formData);
    }
    return this.formDataCopy;
  }

  @Emit("cancel")
  cancel() {
    this.formDataCopy = _.cloneDeep(this.formData);
    this.addressCopy = _.cloneDeep(this.formData[this.addressField.toString()]);
  }

  @Emit()
  onDelete() {
  }

  @Emit()
  onCopy() {
    return this.formDataCopy;
  }

  @Watch("formData")
  async formDataWatch(newData, oldData) {
    console.log("formDataCloned", oldData, newData);
    this.formDataCopy = _.cloneDeep(newData);
    this.addressCopy = _.cloneDeep(
        this.formData[this.addressField.toString()] || {}
    );

    this.onCopy();
    await this.$nextTick();
  }

  @Ref("simpleRules")
  refs_simpleRules!: any;

  @Ref("form")
  form!: HTMLDivElement;

  validationForm() {
    if (this.validate!!)
      this.refs_simpleRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    else this.save();
  }

  @State(state => state.loading.isLoading)
  private _isLoading!: boolean;

  private get isLoading(): boolean {
    return this._isLoading;
  }

  private set isLoading(value: boolean) {
    this._isLoading = value;
  }

  @State(state => state.formLoading.isLoading)
  private _formLoading!: boolean;

  private get formLoading(): boolean {
    return this._formLoading;
  }

  private set formLoading(value: boolean) {
    this._formLoading = value;
  }

  // mounted() {
  //   //@ts-ignore
  //   var can = this.$can(this.$route.meta.resource, 'create')
  //  
  //   if(!can) {
  //     [].slice.call( this.form.elements ).forEach(function(item){
  //       item.disabled = !item.disabled;
  //     });
  //   }
  // }

}
