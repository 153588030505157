import { render, staticRenderFns } from "./FormComponent.vue?vue&type=template&id=56b15c19&scoped=true&"
import script from "./FormComponent.vue?vue&type=script&lang=ts&"
export * from "./FormComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b15c19",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/verification-system-staging/Web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56b15c19')) {
      api.createRecord('56b15c19', component.options)
    } else {
      api.reload('56b15c19', component.options)
    }
    module.hot.accept("./FormComponent.vue?vue&type=template&id=56b15c19&scoped=true&", function () {
      api.rerender('56b15c19', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FormComponent.vue"
export default component.exports